<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        Sipanje goriva
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <form id="new-vehicle-fuel-form">
                            <div class="mb-3 form-floating" v-if="vehicleFuel">
                                <input type="text" class="form-control" id="tId" disabled v-model="vehicleFuel.id" placeholder="Id sipanja">
                                <label for="tId">Id sipanja</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="number" step=".01" class="form-control" id="fiel-liters" v-model="vehicleFuelForm.liters" placeholder="Litara" required>
                                <label for="fiel-liters">Litara</label>
                                <div class="invalid-feedback">
                                    Količina u litrima je obavezno polje.
                                </div>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="number" step=".01" class="form-control" id="fiel-total" v-model="vehicleFuelForm.total" placeholder="Ukupna cena" required>
                                <label for="fiel-total"><font-awesome-icon icon="money-bill-alt" /> Ukupna cena</label>
                                <div class="invalid-feedback">
                                    Ukupna cena je obavezno polje.
                                </div>
                            </div>

                            <div class="mb-3 form-floating">
                                <select v-model="vehicleFuelForm.payment_option" class="form-select" aria-label="Plaćanje" required>
                                    <option disabled :value="undefined">Plaćanje</option>
                                    <option value="CACHE">Keš</option>
                                    <option value="CARD">Kartica</option>
                                </select>
                                <div class="invalid-feedback">
                                    Metod plaćanja je obavezno polje.
                                </div>
                                <label for="floatingTextarea">Metod plaćanja</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <select v-model="vehicleFuelForm.fuel_type" class="form-select" aria-label="Tip goriva" required>
                                    <option disabled :value="undefined">Vrsta goriva</option>
                                    <option value="DIESEL">Dizel</option>
                                    <option value="GASOLINE">Benzin</option>
                                    <option value="GAS">Plin</option>
                                    <option value="ELECTRICITY">Struja</option>
                                </select>
                                <div class="invalid-feedback">
                                    Tip goriva je obavezno polje.
                                </div>
                                <label for="floatingTextarea">Tip goriva</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <select v-if="!gasStations || gasStations.length > 0" v-model="vehicleFuelForm.pump" class="form-select" aria-label="Pumpa" required>
                                    <option disabled :value="undefined">Izaberi pumpu</option>
                                    <option v-for="gasStation in gasStations" :key="gasStation.id" :value="gasStation.name">{{gasStation.name}}</option>
                                </select>
                                
                                <input v-else type="text" class="form-control" id="fiel-total" v-model="vehicleFuelForm.pump" placeholder="Naziv pumpe" required>

                                <label for="fiel-total"><font-awesome-icon icon="gas-pump" /> Pumpa</label>

                                <div class="invalid-feedback">
                                    Uloga obavezno polje.
                                </div>
                            </div>
                            
                            <div class="mb-3 form-floating">
                                <input type="number" :class="{'retroactive-fuel': minTotalDistance >= vehicleFuelForm.total_distance}" class="form-control" id="fiel-total-distance" v-model="vehicleFuelForm.total_distance" :placeholder="minTotalDistance + ' km'" min="0" required>
                                <label for="fiel-liters"><font-awesome-icon icon="tachometer-alt" /> Trenutna kilometraža <template v-if="minTotalDistance > 0">({{minTotalDistance}} km)</template></label>
                                <div class="invalid-feedback">
                                    Trenutna kilometraža.
                                </div>
                                <template v-if="minTotalDistance > 0">
                                    <p v-if="minTotalDistance < vehicleFuelForm.total_distance">Prešao {{vehicleFuelForm.total_distance - minTotalDistance}}km od sipanja</p>
                                    <p v-else-if="vehicleFuelForm.total_distance > 0">Retroaktivno dodavanje</p>
                                </template>
                            </div>

                            <div class="mb-3 d-flex flex-wrap">
                                <div class="form-floating col-7">
                                    <input type="date" class="form-control" id="fiel-tanked-at" v-model="vehicleFuelForm.tanked_at_date" placeholder="Datum" @change="changedTankedDateTime" required>
                                    <label for="fiel-liters">Datum sipanja</label>
                                </div>
                                <div class="form-floating col-5">
                                    <input type="time" class="form-control" id="fiel-tanked-at" v-model="vehicleFuelForm.tanked_at_time" placeholder="Vreme sipanja" @change="changedTankedDateTime" required>
                                    <label for="fiel-liters">Vreme sipanja</label>
                                </div>
                                <div class="invalid-feedback">
                                    Vreme sipanja je obavezno polje.
                                </div>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-receipt-id" v-model="vehicleFuelForm.receipt_id" placeholder="ABCDEFGH-ABCDEFGH-12345">
                                <label for="fiel-liters">Broj računa - (ABCDEFGH-ABCDEFGH-12345)</label>
                                <div class="invalid-feedback">
                                    Link računa je obavezno polje.
                                </div>
                            </div>


                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-receipt-url" v-model="vehicleFuelForm.receipt_url" placeholder="Skenirajte račun i nalepite link">
                                <label for="fiel-liters">Link e-računa QR-kod</label>
                                <div class="invalid-feedback">
                                    Vreme sipanja je obavezno polje.
                                </div>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="woCode" v-model="vehicleFuelForm.description" placeholder="Komentar">
                                <label for="woCode">Komentar</label>
                            </div>
                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                        <button type="button" class="btn btn-primary" @click="createWorkOrder" >{{vehicleFuel?'Izmeni':'Dodaj'}}</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import {mapState} from 'vuex'
    import TransportService from '@/service/TransportService.js'
    import UtilService from '@/service/UtilService.js';

    export default {

        props: {
            inputId: {
                type: String,
                default: "",
            },

            vehicleFuel: {
                type: Object,
                default: null,
            },

            transportVehicle: {
                type: Object,
                default: null,
            },

            dateRange: {
                type: Array,
                default: function () { 
                    return [
                        UtilService.getMoment().add(-90, 'day').toDate(),
                        UtilService.getMoment().toDate()
                    ]
                },
            }
        },

        data() {
            return {
                vehicleFuelForm: {}
            }
        },
        
        computed: {
            ...mapState([
                'user',
                'gasStations',
                'vehicleFuels'
                
            ]),

            minTotalDistance() {
                if (this.vehicleFuel) return 0;

                if (this.vehicleFuels?.length > 0) {
                    if (this.vehicleFuels[0].total_distance > 0) return this.vehicleFuels[0].total_distance
                }

                return 0;

            }
            
        },


        watch:{
            vehicleFuel(){
                this.updateProp();
            }
        },

        created() {
            this.updateProp();
            this.loadGasStations();
        },

        methods: {
            updateProp() {
                if (this.vehicleFuel) {
                    this.vehicleFuelForm = {}
                    this.vehicleFuelForm.id = this.vehicleFuel.id;
                    this.vehicleFuelForm.liters = this.vehicleFuel.liters;
                    this.vehicleFuelForm.total = this.vehicleFuel.total;
                    this.vehicleFuelForm.fuel_type = this.vehicleFuel.fuel_type;
                    this.vehicleFuelForm.payment_option = this.vehicleFuel.payment_option;
                    this.vehicleFuelForm.pump = this.vehicleFuel.pump;
                    this.vehicleFuelForm.driver = this.vehicleFuel.driver;
                    this.vehicleFuelForm.vehicle = this.transportVehicle.id;
                    this.vehicleFuelForm.total_distance = this.vehicleFuel.total_distance;
                    this.vehicleFuelForm.tanked_at = this.vehicleFuel.tanked_at;
                    this.vehicleFuelForm.tanked_at_date = this.vehicleFuel.tanked_at.slice(0,10);
                    this.vehicleFuelForm.tanked_at_time = this.vehicleFuel.tanked_at.slice(11,16);
                    this.vehicleFuelForm.receipt_id = this.vehicleFuel.receipt_id;
                    this.vehicleFuelForm.receipt_url = this.vehicleFuel.receipt_url;
                    this.vehicleFuelForm.comment = this.vehicleFuel.comment;
                } else {
                    this.vehicleFuelForm = {}
                    this.vehicleFuelForm.vehicle = this.transportVehicle.id;
                    this.vehicleFuelForm.driver = this.user.id;
                }
                console.log(this.vehicleFuelForm);
            },

            async loadGasStations() {
                await this.$store.dispatch('loadAllGasStations').catch((error) => {
                    this.error = error;
                });
            },

            async createWorkOrder() {
                let form = document.getElementById("new-vehicle-fuel-form");
                
                form.classList.add('was-validated');

                console.log(form);
                console.log(form.checkValidity());

                if (!form.checkValidity())
                    return;

                if (this.vehicleFuel) {
                    await this.updateFuel();
                }
                else {
                    await this.$store.dispatch('createVehicleFuel', this.vehicleFuelForm)
                    .then(() => {

                        this.toast("Sipanje goriva uspešno.");
                        this.$emit('confirm');

                    })
                    .catch((error) => alert(error.message));    
                }

                this.loadAllVehicleFuel();

            },

            loadAllVehicleFuel() {
                let start = UtilService.dateToUTC(this.dateRange[0]).toISOString().slice(0,10);
                let end = UtilService.dateToUTC(this.dateRange[1]).toISOString().slice(0,10);

                let data = {
                    vehicle: this.transportVehicle.id,

                    params: {
                        start_date: start,
                        end_date: end,
                    }
                }

                this.$store.dispatch('loadAllVehicleFuel', data);

            },

            changedTankedDateTime() {
                if (!this.vehicleFuelForm.tanked_at_date) this.$set(this.vehicleFuelForm, "tanked_at_date", new Date().toISOString().slice(0,10))
                if (!this.vehicleFuelForm.tanked_at_time) this.$set(this.vehicleFuelForm, "tanked_at_time", new Date().toISOString().slice(11,16))
                this.vehicleFuelForm.tanked_at = this.vehicleFuelForm.tanked_at_date + "T" + this.vehicleFuelForm.tanked_at_time
            },

            async updateFuel() {
                    await TransportService.updateVehicleFuel(this.vehicleFuelForm)
                    .then(() => {
                        this.toast("Uspešna izmena.");
                        this.$emit('confirm');
                    })
                    .catch((error) => alert(error.message))
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            }
        },

    }
</script>

<style scoped>
    #new-vehicle-fuel-form .retroactive-fuel {
        background: rgb(255, 250, 94);
    }
</style>